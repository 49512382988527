<template>
  <div class="tags-container">
    <div class="xrow">
      <div class="col-1-1">
        <div class="alert alert-dark">
          <small>
            As tags são usadas para filtrar dados e é importante garantir compatibilidade ao selecionar mais de uma, pois elas são combinadas com a cláusula "E". Por exemplo, ao escolher "Cliente com último pedido em 15 dias" e "Cliente com último pedido no mês passado", pode haver conflito, resultando na não localização do cliente desejado. É essencial garantir que as tags selecionadas sejam coerentes para obter resultados precisos.
          </small>
        </div>
      </div>
    </div>
    <div class="xrow">
      <div class="col-1-3">
        <span class="clientes filters__search">
          <input
            type="text"
            v-model="filter"
            placeholder="Pesquisar tag"
          />
          <fa-icon icon="search" />
        </span>
      </div>
      <div class="col-1-3">
        <div class="tags-selecionadas">
          Tags selecionadas: <strong>{{ tagsSelecionadas.length }} / {{ tags.length }}</strong>
        </div>
      </div>
      <div class="col-1-3">
        <div class="tags-selecionadas">
          <label>
            <input type="checkbox" v-model="filterSelecionadas" />
            Mostrar apenas selecionadas
          </label>
        </div>
      </div>
    </div>
    <div class="xrow">
      <div class="col-1-1">
        <div class="table-container">
          <table class="table table-hover">
            <tbody>
              <tr v-for="tag in tagsFiltradas" @click="check(tag)" :class="tag.checked ? 'tag-checked' : null">
                <td style="width: 40px">
                  <fa-icon
                    :icon='["far", tag.checked ? "check-square" : "square"]'
                    size="2x"
                    class="text-muted"
                  />
                </td>
                <td>
                  <small
                    v-if="tag.chave === null"
                    class="float-right tag-personalizada"
                  >
                    Tag Personalizada
                  </small>
                  <strong>{{ tag.titulo }}</strong>
                  <br>
                  <small class="text-muted">{{ tag.descricao }}</small>
                </td>
              </tr>
              <tr v-if="tagsFiltradas.length === 0">
                <td colspan="2" class="text-center">
                  Nenhuma tag localizada
                  <span v-if="filter !== ''">
                    com o termo <strong>{{ filter }}</strong>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {get} from "@/helpers/apiRequest";
import { arrayFilter } from '@/helpers/utils';
import _findIndex from 'lodash/findIndex';
import _map from 'lodash/map';
import _filter from "lodash/filter";

export default {
  name: "TabelaSelecaoTagFiltro",
  props: [
    "entidade",
    "value",
  ],
  data() {
    return {
      filter: "",
      filterSelecionadas: false,
      tags: [],
      tagsSelecionadas: [],
    }
  },
  computed: {
    tagsFiltradas() {
      let arrayTagsFiltradas =  arrayFilter(this.tags, this.filter, ['titulo', 'descricao']);

      let _allTags = _map(arrayTagsFiltradas, (tag) => {
        tag.checked = _findIndex(this.tagsSelecionadas, (sTag) => sTag.id === tag.id) >= 0;
        return tag;
      });

      return this.filterSelecionadas
          ? _filter(_allTags, {checked: true})
          : _allTags;
    }
  },
  methods: {
    loadTags() {
      get(`/admin/tags-filtro?entidade=${this.entidade}`)
        .then(json => this.tags = json)
    },
    check(tag) {
      let indexTagSelecionada = _findIndex(this.tagsSelecionadas, (sTag) => sTag.id === tag.id);

      if (indexTagSelecionada < 0) {
        this.tagsSelecionadas.push(tag);
        return;
      }

      this.tagsSelecionadas.splice(indexTagSelecionada, 1);
    }
  },
  beforeMount() {
    this.loadTags();
  },
  mounted() {
    this.tagsSelecionadas = this.value || [];
  },
}
</script>

<style scoped>
  .table {
    cursor: pointer;
    line-height: 1.1;
  }
  .table td {
    padding: 4px;
  }
  .tags-selecionadas {
    padding-top: 16px;
  }
  .tags-container {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 2px;
  }
  .tags-container .table-container {
    height: 300px;
    overflow: auto;
  }
  .tag-checked {
    background-color: #f0fbf3;
  }
  .tag-checked svg {
    color: #019200 !important;
  }
  .tag-personalizada {
    border: 1px solid #999;
    border-radius: 3px;
    padding: 1px;
  }
  .alert {
    margin-bottom: 0 !important;
    margin-top: 8px;
    padding: 8px;
  }
</style>