<template>
    <CCard :style="{'background-color': etapa.cor}" class="etapa-funil">
        <CCardBody>
            <strong>{{ etapa.titulo }}</strong>
            <br>
            <div class="etapa-conteudo">
                <small>
                    {{ etapa.descricao}}
                </small>
            </div>
            <button @click="openEdit" class="editar-etapa">
                <CIcon name="cil-pencil"/>
            </button>
            <button @click="openDelete" class="delete-etapa">
                <CIcon name="cil-trash"/>
            </button>
        </CCardBody>
    </CCard>
</template>

<script>
  export default {
    name: "CardEtapa",
    props: [
      'etapa',
      'deleteEtapa',
      'openEditEtapa'
    ],
    methods: {
      openEdit() {
        this.openEditEtapa(this.etapa);
      },
      openDelete() {
        this.deleteEtapa(this.etapa);
      }
    }
  }
</script>

<style scoped>
    .etapa-funil {
        height: 150px;
    }
    .etapa-conteudo {
        max-height: 20px;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }
    .editar-etapa {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
    .delete-etapa {
        position: absolute;
        right: 10px;
        bottom: 30px;
    }
</style>