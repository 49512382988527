<template>
    <CCard>
        <CCardHeader>
            <div class="card-header-actions">
                <CLink href="#" class="card-header-action btn-close">
                    <toggle-button
                        @change="toggleFunilAtivo(funil)"
                        :value="funil.ativo"
                        :sync="true"
                        :labels="{checked: 'Ativo', unchecked: 'Inativo'}"
                        :color="{checked: '#7DCE94', unchecked: '#FF877B', disabled: '#CCCCCC'}"
                        :width="80"
                    />
                </CLink>
                <CLink href="#" class="card-header-action btn-close" v-on:click="openEdit(funil)">
                    <CIcon name="cil-pencil"/>
                </CLink>
                <CLink href="#" class="card-header-action btn-close" v-on:click="deleteFunil(funil)">
                    <CIcon name="cil-trash"/>
                </CLink>
            </div>
                <strong>{{ funil.id }} | {{ funil.titulo }}</strong>
            <br>
            <small>{{ funil.descricao }}</small>
        </CCardHeader>
        <CCardBody>
            <div class="row">
                <div class="col-2" v-for="etapa in funil.etapas">
                    <CardEtapa
                        :etapa="etapa"
                        :openEditEtapa="openEditEtapa"
                        :deleteEtapa="deleteEtapa"
                    />
                </div>
                <div class="col-2">
                    <CButton
                        variant="outline"
                        color="dark"
                        class="etapa-funil"
                        :style="{width: '100%'}"
                        @click="openCreateEtapa(funil.id)">
                        <CIcon name="cil-plus" width="50"/>
                        Incluir etapa
                    </CButton>
                </div>
            </div>
        </CCardBody>
    </CCard>
</template>

<script>
  import CardEtapa from "./CardEtapa";
  export default {
    name: "CardFunil",
    components: {
      CardEtapa
    },
    props: [
      'funil',
      'openEdit',
      'deleteFunil',
      'updateFunil',
      'openCreateEtapa',
      'openEditEtapa',
      'deleteEtapa'
    ],
    methods: {
      toggleFunilAtivo() {
        let _funil = {...this.funil};
        _funil.ativo = !_funil.ativo;
        this.updateFunil(_funil);
      }
    }
  }
</script>

<style scoped>
    .etapa-funil {
        min-height: 150px;
    }
</style>