<style scoped>
  .input-color-picker {
    height: 30px;
    width: 80px;
    line-height: 30px;
    text-align: center;
    position: relative;
    cursor: pointer;
    display: inline-block;
  }
  .current-color {
    border: 1px solid #666;
    border-radius: 3px;
  }
  .backdrop {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: transparent;
    z-index: 10;
  }
  .vc-chrome {
    position: absolute;
    top: -110px;
    right: 84px;
    z-index: 12;
  }
</style>

<template>
  <div>
    <div class="backdrop" v-if="displayPicker" @click="togglePicker()"></div>
    <div class="input-color-picker">
      <div class="current-color" :style="inputStyle" @click="togglePicker()">
          {{ colorValue }}
      </div>
      <chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker" />
    </div>
  </div>
</template>

<script>
  import { Chrome } from 'vue-color'

  export default {
    name: 'InputColorPicker',
    components: {
      'chrome-picker': Chrome
    },
    props: [
      'value'
    ],
    data() {
      return {
        colors: {
          hex: '#000000',
        },
        colorValue: '',
        displayPicker: false,
      }
    },
    computed: {
      inputStyle() {
        return {
          'background-color' : this.colorValue,
          'color' : this.invertedColor(this.colorValue)
        }
      }
    },
    mounted() {
      this.setColor(this.value || '#000000');
    },
    methods: {
      setColor(color) {
        this.updateColors(color);
        this.colorValue = color;
      },
      updateColors(color) {
        if(color.slice(0, 1) == '#') {
          this.colors = {
            hex: color
          };
        }
        else if(color.slice(0, 4) == 'rgba') {
          var rgba = color.replace(/^rgba?\(|\s+|\)$/g,'').split(','),
            hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
          this.colors = {
            hex: hex,
            a: rgba[3],
          }
        }
      },
      togglePicker() {
        this.displayPicker = !this.displayPicker;
      },
      updateFromPicker(color) {
        this.colors = color;
        this.colorValue = color.hex;
      },
      invertedColor(hex) {
        if (hex) {
          if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
          }
          // convert 3-digit hex to 6-digits.
          if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
          }
          if (hex.length < 6) {
            throw new Error('Invalid HEX color.');
          }
          var r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);

          return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
        }

        return '#FFFFFF'
      }
    },
    watch: {
      value(color) {
        this.colorValue = color
      },
      colorValue(val) {
        if(val) {
          this.updateColors(val);
          this.$emit('input', val);
        }
      }
    }
  }
</script>